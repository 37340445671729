@font-face {
  font-family: "kanit";
  src: url("./assets/font/kanit/Kanit-Thin.ttf");
}

@font-face {
  font-family: "varino";
  src: url("./assets/font/VarinonormalRegular-1GXaM.ttf"); 
}  

@font-face {
  font-family: "spacetron";
  src: url("./assets/font/Spacetron-51nWZ.otf");
}

@font-face {
  font-family: "jura";
  src: url("./assets/font/jura/Jura-Medium.ttf");
}


.App {
  text-align: center;
}

*{
  box-sizing: border-box;
  transition: all .7s ;
 
}
body {
  max-width: 100%;
  margin: 0 ;
  background-color: black;
  font-weight: 600;
  z-index: 1;
}

img {
  max-width: 100%;
  height: auto;
}
header {
   
  padding: 1% 5%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  /*background-color: rgba(255,255,255,0.5);*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

#div-logo{

  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

#img-logo{
 max-width: 20%;

}


#saucisson {
  margin: 5%;
  font-size: 2.5em;
  font-family : "varino";
  color : #4ABFC3;
/*  text-shadow: -1px 0 black, 0 1px black,
      1px 0 black, 0 -1px black;*/
}



nav{
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding-left: 30%;*/
}

header nav a {
  text-decoration: none;
  font-size: 1.2rem;
  padding: 1%;
  /*margin: auto 10px;*/
  color: #4ABFC3;
 /* text-shadow: -1px 0 black, 0 1px black,
      1px 0 black, 0 -1px black;*/
  font-family :"varino"
}

header nav a:hover {
    border-bottom: 1px solid #4ABFC3;
}





.container{
  margin-top: 15%;
  background-color: rgba(0,0,0, 0.2);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

#baseline{
  font-size: 3rem;
  color: #4ABFC3;
  font-family :"varino" ;
  line-height: 2;
}


.bandeau a{
  display: block;
  margin: 0 auto;
  width: 20%;
  padding: 1%;
  font-weight: bold;
  text-decoration: none;
  background: black;
  text-align: center;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 2px solid #4ABFC3;

}



.articles{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10%;
  width: 100%;


}

.article1{
  display: flex;
  justify-content: space-between;
  padding-bottom: 3%;
}

.article1 p {
  width: 80%;
  color : white;
  font-family: "kanit";
  text-align: start;
  padding: 0 12%;
  line-height : 2.3em;
  font-size: 2rem;

}

.article1 span{
  color: #4ABFC3 ;
}

#blueDesign{
  opacity: 70%;
  width: 50%;
}

#token{
  max-width: 100%;
 background-color: transparent;
 -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  display: flex;
  flex-direction:row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 10%;
}

#token p{
  margin: 5% auto;
  font-size: 2em;
  padding-left: 3%;
  padding-right: 3%;
  color: #7a7a7a;
  font-family :"kanit" ;
}

.articleToken1{
  width: 40%;
  height : 70%;
  background-color: #161727;
  border-radius: 5%;
  
}



iframe{
    max-width: 100%;
    width: 50%;
    height: 70%;
    border-radius: 5%;
}




table{
  margin : 6% auto;
  width: 70%;
  color: white;
  font-family :"kanit" ;
  font-size: 2em;
}

table td{
  

  text-align: start;
}



.buttonBsc img {
  border-radius: 10px;
  width: 30%;
  margin-right: 39%;
}


.roadmap{
  margin: 0 auto;
  width: 30%;
  font-size: 2em;
  padding: 5%;
}

.sectionRoadmap{
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5%;
}

.sectionRoadmap img{
  width: 70%;
  
  opacity: 80%;

}

#road{
  font-size: 3rem;
  
  color: #4ABFC3;
  font-family :"varino" ;
}


#roadmapTop{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family :"varino";
}

#roadmapBottom{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: "varino";
}


#step1{
  line-height: 30px;
  width: 10%;
  margin-left: 21%;
  color: white;
}

#step3{
  line-height: 30px;
  width: 10%;
  margin-left: 27%;
  color: white;
}

#step2{
  line-height: 30px;
  width: 10%;
  margin-left: 38.5%;
  color: white;
}

#step4{
  line-height: 30px;
  width: 10%;
  margin-left: 27.5%;
  color: white;
}

#light{
  width: 30%;
}

.partners{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex-wrap: wrap;
  
 -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  
}

.add{

  background-image: url('./assets/img/back1.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 

}


#trust{
  margin: 3% auto;
  font-family: "varino";
  font-size: 2.5em;
  color: #7a7a7a;
  width: 100%;
}

#cz{
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  color: #7a7a7a ;
  padding: 1%;
}

#cz img{
  width: 16%;
  
}

.partners p {
  width : 40%;
  font-size: 1.4em;
  font-family: "kanit";
 
}


#elon{
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  color: #7a7a7a ;
  padding: 1%;
}


#justin{
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  color: #7a7a7a ;
  padding: 1%;
}

#justin img{
  width: 16%;
  
}


#elon:hover{
  color: #4ABFC3;
}

#cz:hover{
  color: #4ABFC3;
}

#justin:hover{
  color: #4ABFC3;
}

#elon img{
  width: 16%;
}

/* LOGO DES PARTENAIRES */


#logoPartners{
  width: 100%;
  margin: 3% auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

#logoPartners a{
  
  width: 10%;
}

/* BURGER MENU ----------------------------------- */

/* sidebar Nav */


#menu{
  width: 30px;
  height: 30px;
  color: #4ABFC3;
  padding: 0;
}

.menuBurger {
  width: 20%;
  margin: 0;
}

.menuBurger button {
  
  padding: 10%;
  font-size: 2rem;
  z-index: 10;
  background-color: transparent;
  border: none;
}
.menuBurger #menu {
  width: 1.5em;
  height: 1.5em;
}
.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  padding-top: 7rem;
  padding-left: 0;
  left: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  max-width: 100%;
  z-index: 9;
  text-align: left;
  margin-top: 0;
  background-image :url('./assets/img/back1.jpeg') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.menuNav.showMenu {
  width: 100%;
  margin-bottom: 0;
}

.menuNav>li>a {
  font-size: 1.5rem !important;
  display: block;
  text-align:center;
  text-decoration: none;
  color:#7a7a7a ;
}

@media screen and (max-width: 1024px){
  
  
  
  header {
   
  padding: 1% 5%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  /*background-color: rgba(255,255,255,0.5);*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
  
  
 #div-logo{

  width: 40%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

#img-logo{
 max-width: 20%;

}


#saucisson {
  margin: 5%;
  font-size: 1.5em;
  font-family : "varino";
  color : #4ABFC3;
/*  text-shadow: -1px 0 black, 0 1px black,
      1px 0 black, 0 -1px black;*/
}

#baseline{
  font-size: 2.1rem;
  color: #4ABFC3;
  font-family :"varino" ;
 
}


nav{
  width: 70%;
  display: flex;
  /*padding-left: 30%;*/
   justify-content: end;
}

header nav a {
  text-decoration: none;
  font-size: 1rem;
  padding: 1%;
  margin: auto 10px;
  color: #4ABFC3;
 /* text-shadow: -1px 0 black, 0 1px black,
      1px 0 black, 0 -1px black;*/
  font-family :"varino";
 
}

header nav a:hover {
    border-bottom: 1px solid #4ABFC3;
}
  
  
 #blueDesign{
  display: none;
} 
  
  
.article1{
  width: 100%;
}

.article1 p {
  color : white;
  font-family: "kanit";
  text-align: center;
  padding: 0 12%;
  line-height : 2.3em;
  font-size: 1.8rem;
  width : 100%;

}  



#token{
  width: 100%;
 background-color: transparent;
 -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  display: flex;
  flex-wrap: wrap;
 
  justify-content: space-evenly;
  padding-bottom: 55%;
}

#token p{
  margin: 5% auto;
  font-size: 1.8rem;
  padding-left: 3%;
  padding-right: 3%;
  color: #7a7a7a;
  font-family :"kanit" ;
}

.articleToken1{
  width: 80%;
  max-height: 55%;
  background-color: #161727;
  border-radius: 5%;
  
}



iframe{
    margin-top: 3%;
    width: 80%;
    max-height: 60%;
    border-radius: 5%;
}




table{
  margin : 6% auto;
  width: 70%;
  color: white;
  font-family :"kanit" ;
  font-size: 1.3em;
}

table td{
  
  padding: 2%;
  text-align: start;
}



.buttonBsc img {
  border-radius: 10px;
  width: 30%;
  margin-right: 39%;
}
  
.roadmap{
  margin: 0 auto;
  width: 30%;

  padding-bottom: 3%;
}

.sectionRoadmap{
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10%;
}

.sectionRoadmap img{
width: 70%;

opacity: 80%;

}

  #road{
    font-size: 2rem;
    text-align: center;
    color: #4ABFC3;
    font-family :"varino" ;
  }
  
  
  #roadmapTop{
    width: 90%;
   
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-family :"varino";
  }
  
  #roadmapBottom{
    width: 90%;
  
    margin: 0 auto;
     display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-family: "varino";
  }
  
  
  #step1{
    line-height: 120%;
    width: 20%;
    margin-left: 13.5%;
    color: white;
    font-size: 1em;
  }
  
  #step3{
    line-height: 120%;
    width: 20%;
    margin-left: 21%;
    color: white;
  }
  
  #step2{
    line-height: 120%;
    width: 20%;
    margin-left: 33.4%;
    color: white;
  }
  
  #step4{
    line-height: 120%;
    width: 20%;
    margin-left: 21.3%;
    color: white;
  }
}


@media screen and (max-width:768px){
  
  
  header {
     
    padding: 6% 5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    /*background-color: rgba(255,255,255,0.5);*/
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    align-items: center;
  }
  
  #div-logo{
    
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  
  #img-logo{
   max-width:25%;
  
  }
  
  
  nav{
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    /*padding-left: 30%;*/
  }
  
  
  
  header nav a {
  
    font-size: 1.2rem;
    padding: 5%;
   
  
  }
  
  .bandeau{
    padding-top: 15%;
  }
  
  .bandeau a {
    font-size: 2rem;
    width: 50%;
  }
  
  #baseline{
    font-size: 2.1rem;
    color: #4ABFC3;
    font-family :"varino" ;
    padding: 5% 0;
   
  }  


  #token{
    padding-top: 5%;
    padding-bottom: 75%;
  }
  
  iframe , .articleToken1{
    width: 95%;
  }

  #step1{
      font-size: 0.7em;
    }
  
  #step3{
      font-size: 0.7em;
    }
  
  #step2{
      font-size: 0.7em;
    }
  
  #step4{
      font-size: 0.7em;
    }
  
  #roadmap {
    padding-top: 10%;
  }

  .sectionRoadmap{
    padding-bottom: 10%;
    margin-bottom: 0
  }
  #cz img{
      width: 26%;
    }
  
  #elon img{
      width: 26%;
    }
  
  #justin img{
      width: 26%;
    }

  .partners, #logoPartners{
    padding: 10% 0;
  }
  .partners p {
      width : 40%;
      font-size: 1em;
      font-family: "kanit";
    }

  #logoPartners a{
    width:  40%;
  }
}


  @media screen and (max-width:680px){
  
    #div-logo{
      width: 80%;
    }
    


   
   
   
   .article1 p {
     font-size: 1.2em;
   }
    
      
    #roadmap{
      width: 100%;
    }
  
  
  
  #token p {
  
    font-size: 1.2em;
  }
  
  #road{
    font-size: 1.7rem;
    text-align: center;
    color: #4ABFC3;
    font-family :"varino" ;
  }
  
  
    #step1{
    font-size: 0.7em;
    }
    
    #step3{
    font-size: 0.7em;
    }
    
    #step2{
    font-size: 0.7em;
    }
    
    #step4{
    font-size: 0.7em;
    
    }
    
    #elon p {
     width: 60%;
    
    }
    
    #cz p {
     width: 60%;
    
    }
    
    #justin p {
     width: 60%;
    
    }
  
  #token{
  
    padding-bottom: 60%;
  }
  
  
  .articleToken1{
  
    max-height: 55%;
  
  
    
  }
  
  
  iframe{
    max-height: 50%;
  }
  
  #step1{
    font-size: 0.5em;
  }
  
  #step3{
    font-size: 0.5em;
  }
  
  #step2{
    font-size: 0.5em;
  }
  
  #step4{
    font-size: 0.5em;
  
  }
  
    #ul li a {
   font-size: 1em;
}
  
    
}

@media screen and (max-width:400px){
  
  .articleToken1{
  
    max-height: 55%;
    width: 90%;
  }



  iframe{
    max-height: 50%;
    width: 90%;
  } 

  
   .bandeau{
     margin-top: 20%;
   }  
   
  .article1 p{
    font-size: 1em;
  }
  
  #token p {
      font-size: 1em;
  }
  
  table td {
    font-size: 0.8em;
  }
  
    #token{
  
    padding-bottom: 65%;
  }
  
  #ul li a {
   font-size: 0.7em;

  }
  
  #trust{
    font-size: 1.8em;
  }
  
  
}
